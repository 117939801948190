import * as Sentry from '@sentry/remix'
/**
 * By default, Remix will handle hydrating your app on the client for you.
 * You are free to delete this file if you'd like to, but if you ever want it revealed again, you can run `npx remix reveal` ✨
 * For more information, see https://remix.run/file-conventions/entry.client
 */

import { Theme } from '@carbon/react'
import { RemixBrowser, useLocation, useMatches } from '@remix-run/react'
import { ReactNode, startTransition, StrictMode, useEffect } from 'react'
import { hydrateRoot } from 'react-dom/client'
import { Posthog } from './providers/posthog-provider'

Sentry.init({
	dsn: 'https://238e689dc2740b3d537df1243316e08f@o4508173521584128.ingest.us.sentry.io/4508173527613440',
	tracesSampleRate: 1,

	integrations: [
		Sentry.browserTracingIntegration({
			useEffect,
			useLocation,
			useMatches
		})
	]
})

startTransition(() => {
	hydrateRoot(
		document,
		<StrictMode>
			<Providers>
				<RemixBrowser />
			</Providers>
		</StrictMode>
	)
})

function Providers({ children }: { children: ReactNode }) {
	return <Posthog>{children}</Posthog>
}
